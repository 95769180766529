body {
    font-family: 'Noto Sans', sans-serif;
}

.cu-link {
    @apply text-sm text-blue-700 cursor-pointer hover:underline;
}

.input-chat {
    @apply bg-gray-200 shadow-sm rounded-2xl h-10 md:h-14 pl-8 py-2 pr-16 text-sm placeholder:text-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent focus:bg-white resize-none;
}

.input-button {
    @apply bg-gray-300 hover:bg-white w-10 h-10 rounded-full flex justify-center items-center;
}

.hello-feature-card {
    @apply bg-white border-2 border-gray-200 w-[100px] md:w-[160px] h-[80px] md:h-[120px] flex flex-col justify-center items-center rounded-lg p-3 gap-1 drop-shadow-sm;
}

.hello-feature-card-text {
    @apply text-center text-xs md:text-sm font-semibold text-gray-500;
}

.hello-feature-card-icon {
    @apply w-6 md:w-8 h-6 md:h-8 text-gray-500;
}

.chat-container {
    @apply flex flex-col justify-end items-center bg-slate-200 w-4/6 rounded-lg p-3 gap-2 h-full overflow-y-auto flex-grow;
}

.message-container-user {
    @apply flex justify-items-start items-end w-full flex-col gap-1;
}

.message-container-bot {
    @apply flex justify-items-end items-center w-full flex-col gap-1;
}

.message-user {
    @apply bg-emerald-100/60 px-4 py-2 rounded-3xl max-w-[600px] flex justify-start;
}
  
.message-bot {
    @apply bg-white text-gray-900 p-4 rounded-lg w-fit flex justify-center;
}

/**
* Sparkle
*/

.correct-letter {
    @apply text-white font-semibold bg-emerald-400;
}

.wrong-letter {
    @apply text-white font-semibold bg-gray-400;
}

.bad-placed-letter {
    @apply text-white font-semibold bg-yellow-400;
}

.not-tried-letter {
    @apply bg-white border-2 border-emerald-600;
}

/**
* Colors
*/

.bg-emerald-25 {
    background-color: #f4fff9;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Add borders and padding to the table */
table {
    border-collapse: collapse; /* Ensures borders don't double up */
    width: 100%; /* Ensures the table takes up the container width */
}
  
th, td {
    border: 1px solid #ddd; /* Adds borders to cells */
    padding: 8px; /* Adds spacing inside cells */
    text-align: left; /* Aligns text to the left */
}
  
th {
    background-color: #f2f2f2; /* Light gray background for header */
    font-weight: bold; /* Makes header bold */
}


.reference {
    @apply bg-emerald-500 font-semibold text-xs text-white border-2 border-emerald-600 mx-1 py-[1px] px-[6px] rounded-full;
}
/**
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
  
.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px;
    background-color: #26ce99;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    border: 2px solid #107757;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
  
.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    width: 250px;
    height: 100px;
    overflow-y: scroll;
}
**/

/**
* Lambda
*/

.bg-lambda {
    background-color: hsl(47, 100%, 99%);
}

.bg-lambda-light {
    background-color: #fffefc;
}

.text-lambda-dark {
    color: #776f54;
}

.container {
    --uib-size: 45px;
    --uib-color: black;
    --uib-speed: 2.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: var(--uib-size);
    width: var(--uib-size);
  }

  .slice {
    position: relative;
    height: calc(var(--uib-size) / 6);
    width: 100%;
  }

  .slice::before,
  .slice::after {
    --uib-a: calc(var(--uib-speed) / -2);
    --uib-b: calc(var(--uib-speed) / -6);
    content: '';
    position: absolute;
    top: 0;
    left: calc(50% - var(--uib-size) / 12);
    height: 100%;
    width: calc(100% / 6);
    border-radius: 50%;
    background-color: var(--uib-color);
    flex-shrink: 0;
    animation: orbit var(--uib-speed) linear infinite;
    transition: background-color 0.3s ease;
  }

  .slice:nth-child(1)::after {
    animation-delay: var(--uib-a);
  }

  .slice:nth-child(2)::before {
    animation-delay: var(--uib-b);
  }

  .slice:nth-child(2)::after {
    animation-delay: calc(var(--uib-a) + var(--uib-b));
  }

  .slice:nth-child(3)::before {
    animation-delay: calc(var(--uib-b) * 2);
  }
  .slice:nth-child(3)::after {
    animation-delay: calc(var(--uib-a) + var(--uib-b) * 2);
  }

  .slice:nth-child(4)::before {
    animation-delay: calc(var(--uib-b) * 3);
  }
  .slice:nth-child(4)::after {
    animation-delay: calc(var(--uib-a) + var(--uib-b) * 3);
  }

  .slice:nth-child(5)::before {
    animation-delay: calc(var(--uib-b) * 4);
  }
  .slice:nth-child(5)::after {
    animation-delay: calc(var(--uib-a) + var(--uib-b) * 4);
  }

  .slice:nth-child(6)::before {
    animation-delay: calc(var(--uib-b) * 5);
  }
  .slice:nth-child(6)::after {
    animation-delay: calc(var(--uib-a) + var(--uib-b) * 5);
  }

  @keyframes orbit {
    0% {
      transform: translateX(calc(var(--uib-size) * 0.25)) scale(0.73684);
      opacity: 0.65;
    }
    5% {
      transform: translateX(calc(var(--uib-size) * 0.235)) scale(0.684208);
      opacity: 0.58;
    }
    10% {
      transform: translateX(calc(var(--uib-size) * 0.182)) scale(0.631576);
      opacity: 0.51;
    }
    15% {
      transform: translateX(calc(var(--uib-size) * 0.129)) scale(0.578944);
      opacity: 0.44;
    }
    20% {
      transform: translateX(calc(var(--uib-size) * 0.076)) scale(0.526312);
      opacity: 0.37;
    }
    25% {
      transform: translateX(0%) scale(0.47368);
      opacity: 0.3;
    }
    30% {
      transform: translateX(calc(var(--uib-size) * -0.076)) scale(0.526312);
      opacity: 0.37;
    }
    35% {
      transform: translateX(calc(var(--uib-size) * -0.129)) scale(0.578944);
      opacity: 0.44;
    }
    40% {
      transform: translateX(calc(var(--uib-size) * -0.182)) scale(0.631576);
      opacity: 0.51;
    }
    45% {
      transform: translateX(calc(var(--uib-size) * -0.235)) scale(0.684208);
      opacity: 0.58;
    }
    50% {
      transform: translateX(calc(var(--uib-size) * -0.25)) scale(0.73684);
      opacity: 0.65;
    }
    55% {
      transform: translateX(calc(var(--uib-size) * -0.235)) scale(0.789472);
      opacity: 0.72;
    }
    60% {
      transform: translateX(calc(var(--uib-size) * -0.182)) scale(0.842104);
      opacity: 0.79;
    }
    65% {
      transform: translateX(calc(var(--uib-size) * -0.129)) scale(0.894736);
      opacity: 0.86;
    }
    70% {
      transform: translateX(calc(var(--uib-size) * -0.076)) scale(0.947368);
      opacity: 0.93;
    }
    75% {
      transform: translateX(0%) scale(1);
      opacity: 1;
    }
    80% {
      transform: translateX(calc(var(--uib-size) * 0.076)) scale(0.947368);
      opacity: 0.93;
    }
    85% {
      transform: translateX(calc(var(--uib-size) * 0.129)) scale(0.894736);
      opacity: 0.86;
    }
    90% {
      transform: translateX(calc(var(--uib-size) * 0.182)) scale(0.842104);
      opacity: 0.79;
    }
    95% {
      transform: translateX(calc(var(--uib-size) * 0.235)) scale(0.789472);
      opacity: 0.72;
    }
    100% {
      transform: translateX(calc(var(--uib-size) * 0.25)) scale(0.73684);
      opacity: 0.65;
    }
  }

  .spinner-container {
    --uib-size: 40px;
    --uib-color: purple;
    --uib-speed: 1.5s;
    --dot-size: calc(var(--uib-size) * 0.17);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
    animation: smoothRotate calc(var(--uib-speed) * 1.8) linear infinite;
  }

  .spinner-dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
    animation: rotate var(--uib-speed) ease-in-out infinite;
  }

  .spinner-dot::before {
    content: '';
    height: var(--dot-size);
    width: var(--dot-size);
    border-radius: 50%;
    background-color: var(--uib-color);
    transition: background-color 0.3s ease;
  }

  .spinner-dot:nth-child(2),
  .spinner-dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.835 * 0.5);
  }

  .spinner-dot:nth-child(3),
  .spinner-dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.668 * 0.5);
  }

  .spinner-dot:nth-child(4),
  .spinner-dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.501 * 0.5);
  }

  .spinner-dot:nth-child(5),
  .spinner-dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.334 * 0.5);
  }

  .spinner-dot:nth-child(6),
  .spinner-dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.167 * 0.5);
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    65%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes smoothRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }